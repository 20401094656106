import React from 'react';
import styled from 'styled-components';

import { map } from '@dop/shared/helpers/immutableHelpersWithArgs';
import { IconPijlRechts } from '@dop/ui-icons/svg/pijlRechts';
import { isEmpty } from '@dop/shared/helpers/immutableHelpers';
import { createUnderliner } from '@dop/shared/components/link/blockElements';
import { TextBlock } from '@dop/ui-primitives/typography/TextBlock';
import {
	mqWidth1296px,
	mqWidth880px,
} from '@dop/ui-primitives/base/mediaQueryDefinitions';
import { Box } from '@dop/ui-primitives/planeDivision/Box';

import { Link } from '../../link/link';
import { Heading } from '../heading';
import { hemelblauw, oranje } from '../../../styles/colors';

export const HeadingStyled = styled(Heading)`
	margin-top: 1rem;
	color: ${oranje.default};
`;

const LabelList = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;
`;

const LabelListItemEl = styled.li`
	display: flex;
	font-size: 0.9rem;

	&:before {
		position: relative;
		content: '';
		display: none;
	}
`;

const LabelListLink = styled(Link)`
	color: ${hemelblauw.default};
	border: none;
`;

export const IconStyled = styled(IconPijlRechts)`
	margin-left: 0;
	width: 1.2rem;
	height: 1.6rem;
	margin-right: 0.4rem;
	display: inline-block;
	fill: ${oranje.default};
	flex-shrink: 0;
`;

const Underliner = createUnderliner(LabelListLink);

const LabelListItem = ({ href, text }) => (
	<LabelListItemEl>
		<IconStyled />
		<LabelListLink to={href} checkExternal={false} linkType="mostViewed">
			<Underliner>{text}</Underliner>
		</LabelListLink>
	</LabelListItemEl>
);

export const TopTasks = ({
	linkList,
	className = '',
	title = 'Most viewed',
}) => {
	if (isEmpty(linkList)) return null;

	return (
		<div className={className}>
			{title ? (
				<Box $padding={[1.5, 0]}>
					<TextBlock
						$color={['positive', 'normal']}
						$mq={{
							[mqWidth880px.min]: { $capSize: 1.4 },
							[mqWidth1296px.min]: { $capSize: 1.3 },
						}}
						$fontWeight="bold"
						$capSize={1.5}
					>
						{title}
					</TextBlock>
				</Box>
			) : null}
			<LabelList>
				{map((item, key) => <LabelListItem {...item?.toObject()} key={key} />)(
					linkList,
				)}
			</LabelList>
		</div>
	);
};
